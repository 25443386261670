/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import preloader from '../../../images/preloader.gif'
import styles from './PopupGeolocation.module.css'

export default PopupGeolocation;

function PopupGeolocation(props) {
  const {
    locatedCountry,
    setCountry,
    status,
  } = props;

  function handleYesClick() {
    setCountry(locatedCountry);
    props.closeGeolocationPopup();
  }

  function handleNoClick() {
    props.closeGeolocationPopup();
    props.openChoosePopup();
  }

  return (
    <div className={`${styles.popup} ${styles.popup_open}`}>
      <form className={styles.form_confirm_country}>
        <div className={styles.title_container}>

          {status === 'countryNotFound' &&
            <p className={styles.text}>
              We were not able to found the country you choosed
            </p>}

          {status === 'isLocating' &&
            <>
              <p className={styles.text}>
                We are trying to determine <br /> your country
              </p>
              <img className={styles.preloader} src={preloader} />
            </>}

          {status === 'OK' &&
            <>
              <p className={styles.text}>Would you like to buy airTime for</p>
              <p className={styles.title}>{locatedCountry.CountryName} <br /></p>
            </>}

          {status === 'noProductsForCountry' &&
            <>
              <p className={styles.text}>Your country was identified as <br /></p>
              <h2 className={styles.title}>{locatedCountry.CountryName} <br /></h2>
              <p className={styles.text}>There are no available products for this country.<br /></p>
              <p className={`${styles.text} ${styles.text_last}`}>Choose another country</p>
            </>}

          {status === 'error' &&
            <>
              <p className={styles.text}>
                We were not able to determine your country by IP <br />
              </p>

              <p className={styles.text}>
                Choose a country you would like to buy airTime for from the list
              </p>
            </>}
        </div>


        <div className={styles.buttons_container}>

          {status === 'OK' &&
            <Link to={`/${locatedCountry.CountryIso}`}>
              <button
                type="button"
                className={`${styles.button} ${styles.button_green}`}
                onClick={handleYesClick}
              >
                Yes
              </button>
            </Link>
          }

          <button
            type="button"
            className={`${styles.button} ${styles.button_green} ${status === 'OK' ? `${styles.button_grey}` :
              status === 'isLocating' ? `${styles.button_hidden}` : null}`}
            onClick={handleNoClick}
          >
            {status === 'OK' ? "No" : "OK"}
          </button>
        </div>
      </form>
    </div>
  );
}
