import React, { useEffect } from 'react';

function AboutUs() {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  
  return (
    <main className="page">
      <div className="page__container">
        <h1 className="doc__title-h1">About us</h1>
        <p className="doc__text-p">
          InfokeyAirtime is a Nigerian top up company that
          facilitates recharges
          to cell phones all around the world.
          You can support your loved ones far away or top up your own mobile
          from the comfort of your home.
          Either way, you get foolproof service and best value for money.
        </p>
        <p className="doc__text-p">
          One platform that lets you sell wherever your customers are — online, in person,
          anywhere in the world, and everywhere in between.
        </p>
        <p className="doc__text-p">
          You may call it top up, mobile airtime, mobile credit, mobile load or whatever you want.
          You are in the right place to send credit to a mobile anywhere in the world.
        </p>
        <p className="doc__text-p">
          Our service enables you to send credit to mobiles abroad or to recharge your own.
        </p>
        <p className="doc__text-p">
          The top up process takes no more than a minute and the credit is received instantly.
          Online transactions are 100% smooth and secure.
        </p>
      </div>
    </main>
  );
}

export default AboutUs;
