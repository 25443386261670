import apiUrls from "../constants/apiUrls";

export function buildBackUrl(pathname) {
  let hostname = '';
  let port = '';
  let protocol = '';

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'production') {
    protocol = apiUrls.protocolProd;
    hostname = apiUrls.hostnameProd;
    port = apiUrls.backPortProd;
  } else {
    protocol = apiUrls.protocolDev
    hostname = apiUrls.hostnameDev;
    port = apiUrls.backPortDev;
  }

  const url = new URL(pathname, `${protocol}://${hostname}`);
  url.port = port;
  return url;
}
