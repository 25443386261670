/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProviderCard.module.css'

function ProviderCard(props) {
  const { name, logo, code, provider } = props;

  function handleCardClick() {
    props.onCardClick(provider);
  }

  return (
    <Link className={styles.link} to={`${code}`}>
      <div className={styles.card} onClick={handleCardClick}>
        <div className={styles.logo_container}>
          <img className={styles.logo} src={logo} alt="Provider logo" />
        </div>
        <div className={styles.textarea}>
          <h4 className={styles.name}>{name}</h4>
        </div>
      </div>
    </Link>
  );
}

export default ProviderCard;
