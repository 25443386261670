/* eslint-disable react/prop-types */
import React from 'react';
import phone from '../../images/phone.svg';
import styles from './BuyAirTime.module.css';


function BuyAirTime() {

  return (
    <div className="main">

      <div className={styles.buyairtime}>
        <div className={styles.text}>
          <h1 className={`${styles.text_title} ${styles.text_element}`}>Buy Airtime & Data for all Networks across the globe</h1>
          <p className={`${styles.text_subtitle} ${styles.text_element}`}>Buy airtime for yourself, friends and family. You can set up a monthly, weekly or daily airtime plan for you and your loved ones!</p>
        </div>
        <div className={styles.picture}>
          <img src={phone} alt="phone" />
        </div>
      </div>
    </div>
  );
}

export default BuyAirTime;
