import apiUrls from '../constants/apiUrls.js';
import { buildBackUrl } from './buildBackUrl';

export function postCharge(reqData) {
  const url = buildBackUrl(apiUrls.charge);
  const body = JSON.stringify(reqData);

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Access-Control-Request-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Redirect');

  const reqOptions = {
    method: 'POST',
    headers: headers,
    body: body,
    mode: 'cors',
    redirect: 'follow',
  }

  return fetch(url, reqOptions)
    .then((res) => {
      if (res.ok) return res.json();
      return Promise.reject(`${res?.status} ${res?.statusText}`);
    })
}
