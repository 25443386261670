/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PopupChooseCountry.module.css'
function PopupChooseCountry(props) {
  const { availableCountries } = props;

  function handleClick(country) {
    props.closePopup();
    props.setCountry(country);
  }

  return (
    <div className={`${styles.choose} ${styles.choose_open}`}>
      <div className={styles.content}>
        <h4 className={styles.title}>What country would you like to buy a product for?</h4>
        <section className={styles.countries}>
          {availableCountries.map((country) => (
            <Link to={`${country.CountryIso}`} className={styles.country_link}
              key={country.CountryIso}
              country={country}
              onClick={() => handleClick(country)}
            >{country.CountryName}</Link>
          ))}
        </section>
      </div>
    </div >
  );
}

export default PopupChooseCountry;
