import urlParts from '../constants/apiUrls';
import { buildBackUrl } from './buildBackUrl';

export function getAllProviders() {
  const url = buildBackUrl(urlParts.providers);

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      return Promise.reject('Error in api.getProvideByCode:', err);
    });
}
