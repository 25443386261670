function locateByIp() {
  return fetch('https://fastah-ip.p.rapidapi.com/whereis/v1/json/auto', {
    method: 'GET',
    headers: {
      'x-rapidapi-host': 'fastah-ip.p.rapidapi.com',
      'x-rapidapi-key': '4e48e1daa6msh30659e63373da3ep10bf43jsnd5b2c2648979',
    }
  })
  .then(response => response.json())
  .catch(err => {
    console.error(err);
  });
}

export default locateByIp;
