/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from "react";
import logo from "../../images/group56logo.svg";
import geolocIcon from "../../images/location_outline.svg";
import { useParams, Outlet, Link, useLocation } from "react-router-dom";
import PopupChooseCountry from "../Popups/PopupChooseCountry/PopupChooseCountry";
import PopupGeolocation from "../Popups/PopupGeolocation/PopupGeolocation";
import locateByIp from "../../utils/geolocApi";
import { CountryContext } from "../../context/CountryContext";
import styles from './Header.module.css';

function Header(props) {
  const { allCountries, availableCountries, chosenCountry } = props;
  const [ipLocatedCountry, setIpLocatedCountry] = useState({});
  const [isChooseCountryOpen, setIsChooseCountryOpen] = useState(false);
  const [geolocPopupProps, setGeolocationPopupProps] = useState({
    isOpen: false,
    isPreloader: false,
  });
  const [showCountry, setShowCountry] = useState(false);
  const countryFromContext = useContext(CountryContext);
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    if ("countryIso" in params) {
      setShowCountry(true);
      const iso = params.countryIso.toUpperCase();
      const country = allCountries.find((item) => item.CountryIso === iso);
      props.chooseCountry(country);
      setIpLocatedCountry(country);

      const i = availableCountries.findIndex((item) => item.CountryIso === iso);

      if (i !== -1) {
        props.chooseCountry(availableCountries[i]);
      } else {
        setGeolocationPopupProps({
          ...geolocPopupProps,
          status: "noProductsForCountry",
          buttonText: "OK",
          isOpen: true,
        });
      }
    } else {
      if (
        location.pathname === "/support" ||
        location.pathname === "/about-us" ||
        location.pathname === "/terms-and-conditions" ||
        location.pathname === "/privacy-and-policy"
      ) {
        setShowCountry(false);
      } else {
        setShowCountry(true);
        setGeolocationPopupProps({
          ...geolocPopupProps,
          isOpen: true,
          isPreloader: true,
          status: "isLocating",
        });

        locateByIp()
          .then((data) => {
            const countryFromGeolocation = allCountries.find(
              (item) => item.CountryIso === data.locationData.countryCode
            );
            setIpLocatedCountry(countryFromGeolocation);

            const i = availableCountries.findIndex(
              (item) => item.CountryIso === data.locationData.countryCode
            );

            if (i !== -1) {
              setGeolocationPopupProps({
                ...geolocPopupProps,
                isOpen: true,
                isPreloader: false,
                status: "OK",
              });
            } else {
              setGeolocationPopupProps({
                ...geolocPopupProps,
                isOpen: true,
                isPreloader: false,
                status: "noProductsForCountry",
              });
            }
          })
          .catch(() => {
            setGeolocationPopupProps({
              ...geolocPopupProps,
              isSuccess: false,
              isPreloader: false,
              status: "error",
            });
          });
      }
    }
  }, [location]);

  function closeGeolocation() {
    setGeolocationPopupProps({
      ...geolocPopupProps,
      isOpen: false,
    });
  }

  function closeChoose() {
    setIsChooseCountryOpen(false);
  }

  function openChoosePopup() {
    setIsChooseCountryOpen(true);
  }

  function setCountry(incomingCountry) {
    props.chooseCountry(incomingCountry);
  }

  return (
    <>
      <header className={styles.header}>
        <Link to={"/" + countryFromContext.CountryIso}>
          <div className={styles.logo}>
            <img
              className={styles.logo_image}
              src={logo}
              alt="Infokey logo"
            />
          </div>
        </Link>

        <div className={styles.country_name_container}>
          <div
            className={styles.country_name}
            onClick={openChoosePopup}>
            <img
              className={styles.geoloc_icon}
              src={geolocIcon}
              alt="geolocation"
            />
            <a
              className={styles.country_name_text}
            >
              {showCountry ? chosenCountry.CountryName : 'Click to choose country'}
            </a>
          </div>
        </div>
        <div className={styles.bonus}>
          <p className={styles.bonus_text}>
            2% Bonus Limited time offer on Infokey Airtime
          </p>
        </div>
      </header>

      {geolocPopupProps.isOpen && <PopupGeolocation
        status={geolocPopupProps.status}
        locatedCountry={ipLocatedCountry}
        isOpen={geolocPopupProps.isOpen}
        closeGeolocationPopup={closeGeolocation}
        openChoosePopup={openChoosePopup}
        setCountry={setCountry}
      />}

      {isChooseCountryOpen && <PopupChooseCountry
        closePopup={closeChoose}
        setCountry={setCountry}
        availableCountries={props.availableCountries}
      />}

      <Outlet />
    </>
  );
}
export default Header;
