
import sortBy from 'lodash.sortby';

export default function getAvailableCountries(countries, providers) {
  const allCountries = countries;
  const allProviders = providers;

  const providerCountryCodes = allProviders.map((provider) => {
    return provider.CountryIso;
  })
  
  const listUniqueCountryCodes = Array.from(new Set(providerCountryCodes));
  
  let availableCountries = allCountries.filter((item) => {
    return (listUniqueCountryCodes.includes(item.CountryIso));
  })

  availableCountries = sortBy(availableCountries, ['CountryName'])
  
  return availableCountries ;
}
