/* eslint-disable react/prop-types */
import React from "react";
import styles from './ProductCard.module.css';

function ProductCard(props) {
  const { product, provider } = props;
  const { Name: providerName, LogoUrl: logoUrl } = provider;
  const {
    SendValue: minSendValue,
    SendCurrencyIso: minSendCurIso,
    ReceiveValue: minReceiveValue,
    ReceiveCurrencyIso: minReceiveCurIso,
  } = product.Minimum;
  const {
    SendValue: maxSendValue,
    SendCurrencyIso: maxSendCurIso,
    ReceiveValue: maxReceiveValue,
    ReceiveCurrencyIso: maxReceiveCurIso,
  } = product.Maximum;
  const benefitsArr = Array.from(product.Benefits);
  const benefitsString = benefitsArr.join(", ");
  const isPriceFixed = minSendValue === maxSendValue;

  function onCardClick() {
    props.onClick(product);
  }

  return (
    <div className={styles.card} onClick={onCardClick}>
      <div className={styles.logo_container}>
        <img
          className={styles.logo}
          src={logoUrl}
          alt="Provider logo"
        />
      </div>
      <div>
        <h4 className={styles.name}>{providerName}</h4>
      </div>
      <div>
        <p className={styles.benefits}>{benefitsString}</p>
      </div>

      {!isPriceFixed &&
        <section>
          <div>
            <p className={styles.min}>Minimum</p>
          </div>
          <div>
            <p className={styles.data}>
              {`${minSendValue} ${minSendCurIso} => ${minReceiveValue} ${minReceiveCurIso}`}
            </p>
          </div>
          <div>
            <p className={styles.max}>Maximum</p>
          </div>
          <div>
            <p className={styles.data}>
              {`${maxSendValue} ${maxSendCurIso} => ${maxReceiveValue} ${maxReceiveCurIso}`}
            </p>
          </div>
        </section>
      }

      {isPriceFixed &&
        <section>
          <div>
            <p className={styles.max}>Fix</p>
          </div>

          <div>
            <p className={styles.data}>
              {`${maxSendValue} ${maxSendCurIso} => ${maxReceiveValue} ${maxReceiveCurIso}`}
            </p>
          </div>
        </section>
      }

    </div>

  );
}
export default ProductCard;
