/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { CountryContext } from "../../context/CountryContext";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import BuyAirTime from "../BuyAirTime/BuyAirTime";
import Providers from "../Providers/Providers";
import Products from "../Products/Products";
import AboutUs from "../Footer/AboutUs/AboutUs";
import Support from "../Footer/Support/Support";
import Terms from "../Footer/Terms/Terms";
import Privacy from "../Footer/Privacy/Privacy";
import { getAllProviders } from "../../utils/apiProviders";
import getAvailableCountries from "../../utils/getAvailableCountries";
import { getAllCountries } from "../../utils/apiCountries";

function App() {
  const [chosenCountry, setChosenCountry] = useState({});
  const [allCountries, setAllCountries] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [allProviders, setAllProviders] = useState([]);

  useEffect(() => {
    Promise.all([
      getAllCountries().then((countries) => countries),
      getAllProviders().then((providers) => providers),
    ])
      .then((data) => {
        const [countries, providers] = data;
        setAllCountries(countries);
        setAllProviders(providers);
        setAvailableCountries(getAvailableCountries(countries, providers));
      })
      .then(() => setIsDownloaded(true));
  }, []);

  function chooseCountry(passedCountry) {
    setChosenCountry(passedCountry);
  }

  if (!isDownloaded) { return <div><p>Loading...</p></div> }

  return (
    <CountryContext.Provider value={chosenCountry}>
      <div className="app root">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header
                  chosenCountry={chosenCountry}
                  chooseCountry={chooseCountry}
                  allCountries={allCountries}
                  availableCountries={availableCountries}
                />
                <Footer />
              </>
            }
          >
            <Route index element={<BuyAirTime />} />

            <Route
              path=":countryIso"
              element={
                <>
                  <BuyAirTime />
                  <Providers allProviders={allProviders} />
                </>
              }
            >
              <Route path=":providerCode" element={<Products />}></Route>
            </Route>

            <Route path="about-us" element={<AboutUs />} />
            <Route path="terms-and-conditions" element={<Terms />} />
            <Route path="privacy-and-policy" element={<Privacy />} />
            <Route path="support" element={<Support />} />
            <Route path="*" element={<h1>404: Not Found</h1>} />

          </Route>
        </Routes>

      </div>
    </CountryContext.Provider>
  );
}

export default App;
