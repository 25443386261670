import apiUrls from '../constants/apiUrls';
import { buildBackUrl } from './buildBackUrl';

export function getAllCountries() {
  const url = buildBackUrl(apiUrls.countries);

  return fetch(url, {
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      return Promise.reject('Error in api.getCountries:', err);
    });
}
