import apiUrls from "../constants/apiUrls";
import { buildBackUrl } from "./buildBackUrl";

export function postSupportRequest(data) {
  const url = buildBackUrl(apiUrls.support);

  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const body = JSON.stringify(data);

  const options = {
    method: "POST",
    headers,
    body,
  };

  return fetch(url, options);
}
