/* eslint-disable react/prop-types */
import React from "react";
import failIcon from "../../../images/failed.png";
import successIcon from "../../../images/correct.png";
import infoIcon from "../../../images/information.png";
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './PopupInfo.module.css'

function InfoPopup(props) {
  const { status } = props;

  const location = useLocation();
  const navigate = useNavigate();

  function resetSearchParams() {
    navigate(location.pathname);
  }

  function onClose() {
    if (props.switchIsTransferOpen) {
      props.switchIsTransferOpen(props.openTransferNext);
    }

    if (props.openPaymentNext) {
      props.switchIsPaymentOpen(props.openPaymentNext);
    }

    resetSearchParams();
    props.closePopup();
  }

  return (
    <div className={styles.layout}>
      <div className={styles.body}>
        <div className={styles.content}>
          <img
            className={styles.image}
            src={status === "info" ? infoIcon : status === "failed" ? failIcon : successIcon}
          >
          </img>
          <p>{props.infoText}</p>
          <div className={styles.button_area}>
            <button
              type="button"
              className={`${styles.button} ${styles.btn_close}`}
              onClick={onClose}
            >
              {props.buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoPopup;
