/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useState, useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { CountryContext } from '../../context/CountryContext';
import checkmark from '../../images/checkmark.png';
import ProviderCard from './ProviderCard/ProviderCard';
import styles from './Providers.module.css'

function Providers(props) {
  const { allProviders } = props;
  const params = useParams();
  const country = useContext(CountryContext)

  const [countryProviders, setCountryProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState({});
  const titleRef = useRef(null);

  useEffect(() => {
    if (country.CountryIso === undefined) { return <></> }
    const providers = allProviders.filter((item) => {
      if (item.CountryIso === country.CountryIso) {
        return item;
      }
    });

    setCountryProviders(providers);

    if ('providerCode' in params) {
      const prov = allProviders.find((item) => item.ProviderCode === params.providerCode);
      setSelectedProvider(prov);
    }
  }, [country]);

  useEffect(() => {
    titleRef.current.scrollIntoView();
  }, [countryProviders])

  function onCardClick(provider) {
    setSelectedProvider(provider);
  }

  return (
    <div className={styles.providers}>
      <div className={styles.title}>
        <h2 ref={titleRef} className={styles.title_text}>Choose a Provider<a name="providers" /></h2>
        <button className={styles.title_button} type="button">
          <img src={checkmark} alt="click here to choose a provider" />
        </button>
      </div>

      <section className={styles.grid}>
        {countryProviders.map((item) => (
          <ProviderCard
            key={item.ProviderCode}
            name={item.Name}
            logo={item.LogoUrl}
            code={item.ProviderCode}
            provider={item}
            onCardClick={onCardClick}
          />
        ))}
      </section>
      <Outlet context={[selectedProvider, setSelectedProvider]} />
    </div>
  );
}

export default Providers;
