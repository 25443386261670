import urlParts from '../constants/apiUrls';
import { buildBackUrl } from './buildBackUrl';
import { checkReponse } from './checkResponse';

export function postTransfer(reqData) {
  const url = buildBackUrl(urlParts.transfer);
  const body = JSON.stringify(reqData);

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const reqOptions = {
    method: 'POST',
    headers: headers,
    body: body,
    redirect: 'follow',
  }

  return fetch(url, reqOptions)
    .then(checkReponse)
}
