/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { IMaskInput } from 'react-imask'
import apiUrls from '../../../constants/apiUrls';
import { postTransfer } from '../../../utils/apiTransfers';

function TransferForm(props) {
  const [amount, setAmount] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const refAmount = useRef(null);

  const { product } = props;
  const isPriceFixed = (product.Minimum.SendValue === product.Maximum.SendValue);

  function handleSubmit(evt) {
    evt.preventDefault();

    let transferData = {
      skuCode: product.SkuCode,
      settings: product.SettingDefinitions,
      accountNumber: phone,
      sendValue: Number(refAmount.current.props.value).toFixed(2),
      sendCurrencyIso: product.Minimum.SendCurrencyIso,
      validateOnly: true,
      email: email,
      meta: {
        initialUrl: window.location.href,
      },
      redirectUrl: process.env.NODE_ENV === 'production' ? apiUrls.redirectUrlProd : apiUrls.redirectUrlDev,
    };

    let uat = product.UatNumber;
    uat = uat.substring(0, uat.length - 1) + '1';
    //uatNumber is used for testing
    if (process.env.NODE_ENV !== 'production') {
      transferData.accountNumber = uat;
    }

    props.updateTransferData(transferData);

    postTransfer(transferData)
      .then((res) => {
        if (res.ResultCode === 1) {
          props.updateTransferData({
            ...transferData,
            distributorRef: res.TransferRecord.TransferId.DistributorRef,
          })
          props.onClose();
          props.switchIsPaymentOpen(true);
        } else {
          const infoPopupProps = {
            status: 'failed',
            buttonText: 'Close',
            infoText: `Error: ${res.ErrorCodes[0].Code}`,
          }

          props.setInfoProps(infoPopupProps);
          props.openInfo();
          props.switchIsTransferOpen(false);
          props.switchOpenTransferNext(true);
        }
      })
      .catch(() => {
        const infoPopupProps = {
          status: 'failed',
          buttonText: 'Close',
          infoText: `Error: Internal server error.\nTry again later.`,
        }
        props.switchIsTransferOpen(false);
        props.setInfoProps(infoPopupProps);
        props.openInfo();
      })
  }

  function handleClose(evt) {
    evt.preventDefault();
    props.onClose();
  }

  return (
    <div id="transfer-popup"
      className={'transfer-popup transfer-popup_open'}
      onClick={props.onClose}
    >
      <div className="transfer-popup__body">
        <form className="transfer-popup__form"
          onSubmit={handleSubmit}
          onClick={(e) => e.stopPropagation()}>

          <div className="transfer-popup__content__info">
            <div className="content__howmuch">
              <div >
                <p className="content__howmuch_title">Amount</p>
                <p className="content__label-text">
                  {isPriceFixed
                    ? `${product.Minimum.SendValue}${'\u00A0'}${product.Minimum.SendCurrencyIso} =>
                    ${product.Minimum.ReceiveValue}${'\u00A0'}${product.Minimum.ReceiveCurrencyIso}`
                    : `Enter amount from ${product.Minimum.SendValue}${'\u00A0'}${product.Minimum.SendCurrencyIso}
                     to ${product.Maximum.SendValue}${'\u00A0'}${product.Maximum.SendCurrencyIso}`
                  }
                </p>
              </div>

              <p>
                <IMaskInput
                  className="input content__howmuch_input"
                  required
                  autoComplete="off"
                  id="amount"
                  name="amount"
                  ref={refAmount}
                  mask={Number}
                  radix="."
                  scale={2}  // digits after point, 0 for integers
                  signed={false}
                  min={product.Minimum.SendValue}
                  max={product.Maximum.SendValue}
                  // inputRef={el => this.input = el}
                  disabled={isPriceFixed}
                  value={isPriceFixed ? `${product.Maximum.SendValue}` : amount}
                  data-error="Invalid amount"
                  onAccept={(value) => setAmount(value)}
                />
                {` ${product.Minimum.SendCurrencyIso}`}
              </p>
            </div>

            <div className="content__phone">
              <div className="content__address_title-transfer">Phone number you buy for</div>
              <input
                className="input content__address_input-transfer"
                // ref={refPhone}
                // inputRef={(el) => (this.input = el)}
                // mask={mask}
                // required
                autoComplete="off"
                type="tel"
                id="phone"
                name="phone"
                value={phone}
                onInput={(e) => {
                  setPhone(e.target.value)
                }}
              />
            </div>

            <div className="content__address">
              <div className="content__address_title-transfer">Email Address</div>
              <input
                className="input content__address_input-transfer"
                required
                autoComplete="off"
                type="email"
                id="email"
                name="email"
                data-error="Invalid email"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="content__buttons_transfer">

            <button type="button" className="button buttons__btn-body buttons__btn-body_grey" onClick={handleClose}>
              Close
            </button>

            <button type="submit" className="button buttons__btn-body buttons__btn-body_green">
              Continue
            </button>

          </div>
        </form>

      </div>
    </div >
  )
}

export default TransferForm;
